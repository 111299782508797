import React, { useState, useEffect } from 'react';
import moment from 'moment';
import axios from 'axios';
import clsx from 'clsx';
import { navigate } from 'gatsby';
import Form from '@soosap/sushi/Form';
import { SlotFieldOption } from '@soosap/sushi/Form/SlotField';
import { useToasts, ToastTheme } from '@soosap/sushi/Toast';
import { BusinessHours } from 'server/src/app/opening/types';
import { ConfirmationMethod } from 'server/src/app/reservation/types';
import Selection from './Selection';
import CustomerDetails from './CustomerDetails';
import CallToAction from './CallToAction';
import Description from './Description';
import WarningIcon from 'icons/Warning';
import FoodIcon from 'icons/Food';
import styles from './Reservation.module.scss';

const { GATSBY_BACKEND_URL } = process.env;
const isClient = typeof window !== 'undefined';

export interface FormValues {
  reserveAt: string;
  timeSlot: string;
  confirmationMethod: ConfirmationMethod;
  customerName: string;
  mobileNumber?: string;
  email?: string;
  rememberMe: boolean;
  personCount: number;
}

export interface Props {
  className?: string;
  hours: BusinessHours;
  disabled?: boolean;
}

const Reservation: React.FC<Props> = ({
  className,
  hours,
  disabled = false,
}) => {
  const [slots, setSlots] = useState<SlotFieldOption[]>();
  const { addToast } = useToasts();

  const onSubmit = async (values: FormValues) => {
    try {
      if (values.rememberMe) {
        localStorage.setItem('customerName', values.customerName);

        if (values.mobileNumber) {
          localStorage.setItem('mobileNumber', values.mobileNumber);
        }

        if (values.email) {
          localStorage.setItem('email', values.email);
        }
      } else {
        localStorage.clear();
      }

      const { status } = await axios.post(
        `${GATSBY_BACKEND_URL}/api/reservations`,
        values
      );

      if (status === 201) {
        navigate('/');

        addToast(
          <div>
            <span>Reservierungsanfrage erhalten!</span>
            <br />
            <span>
              {values.personCount} Person{values.personCount > 1 ? 'en' : ''} am{' '}
              {moment(values.reserveAt).format('DD.MM.')} um {values.timeSlot}{' '}
              Uhr
            </span>
          </div>,
          {
            theme: ToastTheme.SUCCESS,
            autoDismiss: false,
            icon: FoodIcon,
          }
        );

        if (isClient) {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: 'call-to-action',
            category: 'Reservation',
            action: 'Place',
            value: values.personCount * 10, // we assume that a single person brings in 10 EUR in revenue
            label: `${moment(values.reserveAt).format('DD.MM.')} ▷ ${
              values.timeSlot
            } ▷ ${values.personCount}`,
          });
        }
      }
    } catch (e) {
      addToast(`Fehler! Bitte anrufen ☎ 07131 405 11 70`, {
        theme: ToastTheme.ERROR,
        autoDismiss: false,
        icon: WarningIcon,
      });
    }
  };

  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit, values: formValues }) => {
        const values = formValues as FormValues;

        useEffect(() => {
          if (!values.reserveAt) return;

          const fetchData = async () => {
            const reservationKey = moment(values.reserveAt).format(
              'YYYY-MM-DD'
            );

            const { data } = await axios.get<SlotFieldOption[]>(
              `${GATSBY_BACKEND_URL}/api/reservations/${reservationKey}/slots`
            );

            setSlots(data);
          };

          fetchData();
        }, [values.reserveAt]);

        return (
          <form
            className={clsx(styles['Reservation'], className)}
            onSubmit={handleSubmit}
          >
            <div className={styles['Reservation__inner']}>
              {disabled ? (
                <div className={styles['Reservation__disabled']}>
                  Aktuell sind keine Reservierungen möglich.
                </div>
              ) : (
                <>
                  <Selection
                    className={styles['Reservation__selection']}
                    hours={hours}
                    slots={slots}
                  />
                  <CustomerDetails
                    className={styles['Reservation__customerDetails']}
                  />
                  <CallToAction
                    className={styles['Reservation__cta']}
                    disabled={disabled}
                  />
                  <Description className={styles['Reservation__description']} />
                </>
              )}
            </div>
          </form>
        );
      }}
    />
  );
};

export default Reservation;
