import React from 'react'
import clsx from 'clsx'
import { useFormState } from 'react-final-form'
import Button from '@soosap/sushi/Button'
import Spinner, { SpinnerTheme } from '@soosap/sushi/Spinner'
import Container from 'atoms/Container'
import styles from './CallToAction.module.scss'

export interface Props {
  className?: string
  disabled: boolean
}

const CallToAction: React.FC<Props> = ({ className, disabled }) => {
  const { invalid, submitting } = useFormState({
    subscription: {
      invalid: true,
      submitting: true,
    },
  })

  return (
    <Container className={clsx(styles['CallToAction'], className)}>
      <Container.Inner>
        <Button
          primary
          type="submit"
          disabled={invalid || submitting || disabled}
          onMouseDown={(e) => e.preventDefault()}
        >
          {submitting ? (
            <>
              <Spinner
                className="GiftCard__spinner"
                theme={SpinnerTheme.LIGHT}
              />
              <span>In Bearbeitung</span>
            </>
          ) : (
            `Anfragen`
          )}
        </Button>
      </Container.Inner>
    </Container>
  )
}

export default CallToAction
