import React from 'react';
import clsx from 'clsx';
import { useFormState } from 'react-final-form';
import { ConfirmationMethod } from 'server/src/app/reservation/types';
import Title from 'atoms/Title';
import Container from 'atoms/Container';
import RadioField from '@soosap/sushi/Form/RadioField';
import { FormValues } from '../Reservation';
import EmailAddress from './EmailAddress';
import MobileNumber from './MobileNumber';
import RememberMe from './RememberMe';
import styles from './CustomerDetails.module.scss';

export interface Props {
  className?: string;
}

const CustomerDetails: React.FC<Props> = ({ className }) => {
  const { values } = useFormState<FormValues>();

  return (
    <Container>
      <Title>Kontakt</Title>
      <Container.Inner className={clsx(styles['CustomerDetails'], className)}>
        <div className={styles['CustomerDetails__confirmation']}>
          <RadioField
            name="confirmationMethod"
            initialValue={ConfirmationMethod.MOBILE}
            horizontal
          >
            <RadioField.Option
              label="Mobiltelefon"
              value={ConfirmationMethod.MOBILE}
            />
            <RadioField.Option
              label="E-Mail"
              value={ConfirmationMethod.EMAIL}
            />
          </RadioField>
        </div>
        {values.confirmationMethod === ConfirmationMethod.MOBILE ? (
          <MobileNumber />
        ) : (
          <EmailAddress />
        )}
        <RememberMe />
      </Container.Inner>
    </Container>
  );
};

export default CustomerDetails;
