import React from 'react';

import styles from './Description.module.scss';

export interface Props {
  className?: string;
  title?: string;
  subtitle?: string;
}

const Description: React.FC<Props> = ({
  className,
  children,
  title,
  subtitle,
}) => {
  return (
    <div className={`${styles[`Description`]} ${className ? className : ''}`}>
      {(title || subtitle) && (
        <div className={styles['Description__header']}>
          {title && <h4 className={styles['Description__title']}>{title}</h4>}
          {subtitle && (
            <div className={styles['Description__subtitle']}>{subtitle}</div>
          )}
        </div>
      )}
      <div className={styles['Description__body']}>{children}</div>
    </div>
  );
};

export default Description;
