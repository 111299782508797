import React, { useState } from 'react';
import clsx from 'clsx';
import FieldSet from '@soosap/sushi/FieldSet';
import EmailField from '@soosap/sushi/Form/EmailField';
import TextField from '@soosap/sushi/Form/TextField';
import styles from './EmailAddress.module.scss';

const isClient = typeof window !== 'undefined';

export interface Props {
  className?: string;
}

const EmailAddress: React.FC<Props> = ({ className }) => {
  const [email] = useState<string>(
    (isClient && localStorage.getItem('email')) || ''
  );

  const [customerName] = useState<string>(
    (isClient && localStorage.getItem('customerName')) || ''
  );

  return (
    <FieldSet
      className={clsx(styles['EmailAddress'], className)}
      title="Bestätigung via E-Mail"
    >
      <EmailField
        name="email"
        label="Ihre E-Mail Adresse"
        placeholder="z.B. matangi@gmx.de"
        required
        initialValue={email}
      />
      <TextField
        name="customerName"
        label="Ihr Name"
        placeholder="z.B. Matangi Arulpragasam"
        required
        initialValue={customerName}
      />
    </FieldSet>
  );
};

export default EmailAddress;
