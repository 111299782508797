import React from 'react';
import clsx from 'clsx';
import { BusinessHours } from 'server/src/app/opening/types';
import { SlotFieldOption } from '@soosap/sushi/Form/SlotField';
import Container from 'atoms/Container';
import Title from 'atoms/Title';
import DayPicker from './DayPicker';
import TimeSlot from './TimeSlot';
import PersonCount from './PersonCount';
import Seating from './Seating';
import styles from './Selection.module.scss';

export interface Props {
  className?: string;
  hours: BusinessHours;
  slots?: SlotFieldOption[];
}

const Selection: React.FC<Props> = ({ className, hours, slots }) => {
  return (
    <Container className={clsx(styles['Selection'], className)}>
      <Title className={styles['Reservation__subtitle']}>Reservierung</Title>
      <Container.Inner className={styles['Selection__inner']}>
        <div className={styles['Selection__leftColumn']}>
          <DayPicker className={styles['Selection__dayPicker']} hours={hours} />
        </div>
        <div className={styles['Selection__rightColumn']}>
          <TimeSlot className={styles['Selection__timeSlot']} slots={slots} />
          <PersonCount
            className={styles['Selection__personCount']}
            slots={slots}
          />
          <Seating className={styles['Selection__seating']} slots={slots} />
        </div>
      </Container.Inner>
    </Container>
  );
};

export default Selection;
