import React, { useState } from 'react';
import clsx from 'clsx';
import CheckboxField from '@soosap/sushi/Form/CheckboxField';
import styles from './RememberMe.module.scss';

const isClient = typeof window !== 'undefined';

export interface Props {
  className?: string;
}

const RememberMe: React.FC<Props> = ({ className }) => {
  const [customerName] = useState<string>(
    (isClient && localStorage.getItem('customerName')) || ''
  );

  return (
    <div className={clsx(styles['RememberMe'], className)}>
      <CheckboxField
        name="rememberMe"
        label="Eingaben merken für die nächste Reservierung"
        initialValue={customerName ? true : false}
      />
    </div>
  );
};

export default RememberMe;
