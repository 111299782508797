import React from 'react';
import clsx from 'clsx';
import Description from 'atoms/Description';
import styles from './Description.module.scss';

export interface Props {
  className?: string;
}

const MyDescription: React.FC<Props> = ({ className }) => {
  return (
    <Description
      className={clsx(styles['Description'], className)}
      title="Bestätigung"
      subtitle="Binnen 1-2h"
    >
      Sehr geehrte Gäste, wir freuen uns Ihnen mit nur wenigen Klicks eine Tisch
      Reservierung im THAMARAI Restaurant in Heilbronn anbieten zu können. Dabei
      geben wir Ihnen in Echtzeit Auskunft darüber ob und zu welchen Zeiten wir
      noch verfügbare Tische haben. Die Bestätigung erfolgt i.d.R. innerhalb
      1-2h. Bei mehr als 6 Personen bitten wir um telefonische Reservierung
      unter <span className="Description__telephone">07131 405 11 70</span>.
    </Description>
  );
};

export default MyDescription;
