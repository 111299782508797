import React, { useState } from 'react';
import clsx from 'clsx';
import FieldSet from '@soosap/sushi/FieldSet';
import TextField from '@soosap/sushi/Form/TextField';
import PhoneField from '@soosap/sushi/Form/PhoneField';
import styles from './MobileNumber.module.scss';

const isClient = typeof window !== 'undefined';

export interface Props {
  className?: string;
}

const MobileNumber: React.FC<Props> = ({ className }) => {
  const [mobileNumber] = useState<string>(
    (isClient && localStorage.getItem('mobileNumber')) || ''
  );

  const [customerName] = useState<string>(
    (isClient && localStorage.getItem('customerName')) || ''
  );

  return (
    <FieldSet
      className={clsx(styles['MobileNumber'], className)}
      title="Bestätigung via Mobiltelefon"
    >
      <PhoneField
        name="mobileNumber"
        label="Ihre Handynummer"
        placeholder="z.B. 0176 123 123 99"
        initialValue={mobileNumber}
        required
      />
      <TextField
        name="customerName"
        label="Ihr Name"
        placeholder="z.B. Matangi Arulpragasam"
        initialValue={customerName}
        required
      />
    </FieldSet>
  );
};

export default MobileNumber;
