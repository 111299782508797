import React from 'react';
import clsx from 'clsx';
import ButtonField from '@soosap/sushi/Form/ButtonField';
import { SeatingOption } from 'server/src/app/reservation/types';
import { SlotFieldOption } from '@soosap/sushi/Form/SlotField';
import styles from './Seating.module.scss';

export interface Props {
  className?: string;
  slots?: SlotFieldOption[];
}

const Seating: React.FC<Props> = ({ className, slots }) => {
  return slots ? (
    <div className={clsx(styles['Seating'], className)}>
      <ButtonField
        name="seating"
        initialValue={SeatingOption.INDOOR}
        label="Platzierung"
      >
        <ButtonField.Option label="Innen" value={SeatingOption.INDOOR} />
        <ButtonField.Option label="Terrasse" value={SeatingOption.OUTDOOR} />
      </ButtonField>
    </div>
  ) : null;
};

export default Seating;
