import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImageProps } from 'gatsby-image';
import { BusinessHours } from 'server/src/app/opening/types';

import Layout from 'molecules/Layout';
import Reservation from 'organisms/Reservation';

export const RESERVATION_QUERY = graphql`
  query ReservationQuery {
    logo: file(relativePath: { eq: "logos/thamarai-reservierung-logo.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 375, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    hours(id: { eq: "dineIn" }) {
      monday {
        id
        label
        abbrev
        lunchActive
        lunchFrom
        lunchTo
        dinnerActive
        dinnerFrom
        dinnerTo
        position
      }
      tuesday {
        id
        label
        abbrev
        lunchActive
        lunchFrom
        lunchTo
        dinnerActive
        dinnerFrom
        dinnerTo
        position
      }
      wednesday {
        id
        label
        abbrev
        lunchActive
        lunchFrom
        lunchTo
        dinnerActive
        dinnerFrom
        dinnerTo
        position
      }
      thursday {
        id
        label
        abbrev
        lunchActive
        lunchFrom
        lunchTo
        dinnerActive
        dinnerFrom
        dinnerTo
        position
      }
      friday {
        id
        label
        abbrev
        lunchActive
        lunchFrom
        lunchTo
        dinnerActive
        dinnerFrom
        dinnerTo
        position
      }
      saturday {
        id
        label
        abbrev
        lunchActive
        lunchFrom
        lunchTo
        dinnerActive
        dinnerFrom
        dinnerTo
        position
      }
      sunday {
        id
        label
        abbrev
        lunchActive
        lunchFrom
        lunchTo
        dinnerActive
        dinnerFrom
        dinnerTo
        position
      }
    }
  }
`;

interface Data {
  logo: {
    childImageSharp: GatsbyImageProps;
  };
  hours: BusinessHours;
}

export interface Props {}

const ReservationPage: React.FC<Props> = () => {
  const { logo, hours } = useStaticQuery<Data>(RESERVATION_QUERY);

  return (
    <Layout
      searchEngineOptimization={() => (
        <Layout.SEO
          path="/tisch-reservieren"
          canonical="/tisch-reservieren"
          lang="de-DE"
          title="Reservierung"
          description="Sichern Sie sich einen Tisch bei THAMARAI ganz einfach per Online-Reservierung. Nach Prüfung der Kapazität erhalten Sie umgehend eine Bestätigung."
        />
      )}
      header={() => (
        <Layout.Header
          title="Tisch Reservieren im THAMARAI Restaurant"
          subtitle="Heilbronn wird modern: Online-Reservierung mit wenigen Klicks"
          abstract="Sichern Sie sich einen Tisch bei THAMARAI ganz einfach und bequem über unsere Webseite. Wir prüfen ob wir noch genügend Kapazitäten haben um Ihre Anfrage zu erfüllen. Sie erhalten in der Regel in weniger als einer Stunde eine Bestätigung an die angegebene E-Mail Adresse bzw. Mobilfunknummer. Falls wir Ihren Wunsch nicht erfüllen können, unterbreiten wir Ihnen einen Alternativvorschlag. Bei Anfragen von mehr als 6 Personen bitten wir um eine telefonische Tisch-Reservierung. Wünschen Sie eine Platzierung auf der Terrasse? Lassen Sie es uns wissen - im Formular gibt es ein eigens dafür vorgesehenes Feld. Wir wünschen viel Spaß beim Reservieren und freuen uns Sie schon bald bei uns im Hause in Heilbronn begrüßen zu dürfen."
          alt="THAMARAI Reservierung"
        />
      )}
    >
      <div className={`ReservationPage`}>
        <Reservation hours={hours} />
      </div>
    </Layout>
  );
};

export default ReservationPage;
