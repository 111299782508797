import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import { BusinessHours } from 'server/src/app/opening/types';
import 'moment/locale/de';
import DateField, {
  DaysOfWeekModifier,
  RangeModifier,
} from '@soosap/sushi/Form/DateField';
import styles from './DayPicker.module.scss';

export interface Props {
  className?: string;
  hours: BusinessHours;
}

const DayPicker: React.FC<Props> = ({ className, hours }) => {
  const [today] = useState<Date>(new Date());
  const [nextOpenDay, setNextOpenDay] = useState<Date>();
  const [firstDayOfMonth] = useState(
    new Date(today.getFullYear(), today.getMonth(), 1)
  );
  const [yesterday] = useState(
    new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1)
  );
  const [disabledDays, setDisabledDays] = useState<
    (Date | DaysOfWeekModifier | RangeModifier)[]
  >();

  useEffect(() => {
    const disabledDaysOfWeek = Object.values(hours)
      .sort((a, b) => a.position - b.position)
      .reduce((prev, curr) => {
        return curr.lunchActive || curr.dinnerActive
          ? prev
          : [...prev, curr.position];
      }, [] as number[]);

    const disabledDays: (Date | DaysOfWeekModifier | RangeModifier)[] = [
      { from: firstDayOfMonth, to: yesterday },
      { daysOfWeek: disabledDaysOfWeek },
    ];

    const todayWeekday = moment(today).isoWeekday();
    const todayHours = Object.values(hours).find(
      (h) => h.position === todayWeekday
    );

    const todayClosingTime = todayHours?.dinnerActive
      ? todayHours.dinnerTo
      : todayHours?.lunchActive
      ? todayHours.lunchTo
      : undefined;

    let todayDisabled = false;

    if (todayClosingTime) {
      const closingTime = moment(today)
        .set('hour', Number(todayClosingTime.split(':')[0]))
        .set('minute', Number(todayClosingTime.split(':')[1]));

      const remainingMinutes = moment(closingTime).diff(today, 'minute');
      // -60min Warme Küche schließt eine Stunde vor Geschäftsschluss
      // -60min also eine Stunde vor Ende Warme Küche kann man noch reservieren
      todayDisabled = remainingMinutes <= 120;
    } else {
      todayDisabled = true;
    }

    if (todayDisabled) {
      disabledDays.push(today);

      let open;
      let nextDay = moment(today).add(1, 'day');

      while (open === undefined) {
        if (disabledDaysOfWeek.includes(moment(nextDay).isoWeekday())) {
          nextDay = moment(nextDay).add(1, 'day');
        } else {
          open = nextDay;
          setNextOpenDay(nextDay.toDate());
        }
      }
    } else {
      setNextOpenDay(today);
    }

    setDisabledDays(disabledDays);
  }, []);

  if (!nextOpenDay) return null;

  return (
    <div className={clsx(styles['DayPicker'], className)}>
      <DateField
        name="reserveAt"
        locale="de"
        fromMonth={new Date(today.getFullYear(), today.getMonth(), 1)}
        toMonth={new Date(today.getFullYear(), today.getMonth() + 1, 1)}
        disabledDays={disabledDays}
        initialValue={nextOpenDay}
        preventUnselect
        required
      />
    </div>
  );
};

export default DayPicker;
