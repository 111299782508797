import React from 'react';
import clsx from 'clsx';
import ButtonField from '@soosap/sushi/Form/ButtonField';
import { SlotFieldOption } from '@soosap/sushi/Form/SlotField';
import styles from './PersonCount.module.scss';

export interface Props {
  className?: string;
  slots?: SlotFieldOption[];
}

const PersonCount: React.FC<Props> = ({ className, slots }) => {
  return slots ? (
    <div className={clsx(styles['PersonCount'], className)}>
      <ButtonField name="personCount" initialValue={2} label="Personenzahl">
        <ButtonField.Option label="1" value={1} />
        <ButtonField.Option label="2" value={2} />
        <ButtonField.Option label="3" value={3} />
        <ButtonField.Option label="4" value={4} />
        <ButtonField.Option label="5" value={5} />
        <ButtonField.Option label="6" value={6} />
      </ButtonField>
    </div>
  ) : null;
};

export default PersonCount;
