import { Environment } from '../../types';

export enum ConfirmationMethod {
  EMAIL = 'email',
  MOBILE = 'mobile',
}

export enum SeatingOption {
  INDOOR = 'indoor',
  OUTDOOR = 'outdoor',
}

export enum ReservationStatus {
  PENDING = 'pending',
  CONFIRMED = 'confirmed',
  DECLINED = 'declined',
  CANCELLED = 'cancelled',
  NO_SHOW = 'no-show',
}

export interface TimeSlot {
  label: string;
  value: string;
  disabled: boolean;
  range: string;
  personCount?: number;
}

export interface Reservation {
  id: string;
  env: Environment;
  status: ReservationStatus;
  reserveAt: string;
  createdAt: string;
  respondedAt?: string; // confirm or decline
  cancelledAt?: string;
  personCount: number;
  confirmationMethod: ConfirmationMethod;
  customerName: string;
  mobileNumber?: string;
  email?: string;
  seating: SeatingOption;
}
