import React from 'react';
import clsx from 'clsx';
import { useFormState } from 'react-final-form';
import Spinner from '@soosap/sushi/Spinner';
import SlotField, { SlotFieldOption } from '@soosap/sushi/Form/SlotField';
import { FormValues } from '../../Reservation';
import styles from './TimeSlot.module.scss';

export interface Props {
  className?: string;
  slots?: SlotFieldOption[];
}

const TimeSlot: React.FC<Props> = ({ className, slots }) => {
  const { values } = useFormState<FormValues>();

  if (!values.reserveAt) return null;

  return (
    <div className={clsx(styles['TimeSlot'], className)}>
      {slots ? (
        <SlotField
          className={styles['TimeSlot__field']}
          name="timeSlot"
          label="Uhrzeit"
          options={slots}
          required
          initialValue={slots.filter((slot) => !slot.disabled)[0].value}
        />
      ) : (
        <Spinner />
      )}
    </div>
  );
};

export default TimeSlot;
